import Header from "./Header";
import Search from "../components/Search";
import Upload from "../components/Upload";
import AppointmentsTable from "../components/AppointmentsTable";
import FunctionProvider from "../FunctionContext";

function Home() {
  return (
    <FunctionProvider>
      <div>
        <div>
          <Header />

          <Search />
          <Upload />
        </div>
        <div>
          <AppointmentsTable />
        </div>
      </div>
    </FunctionProvider>
  );
}

export default Home;
