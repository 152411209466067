import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

//import history from "./history";
import Home from "./pages/Home";

class AllRoutes extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default AllRoutes;
