import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";

import MenuItem from "@mui/material/MenuItem";
import NiagaraLogo from "../assets/Niagara.svg";
import PersonIcon from "@mui/icons-material/Person2";
import { useMsal } from "@azure/msal-react";

function Header() {
  const { accounts } = useMsal();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const loggedinUserName = accounts.length > 0 ? accounts[0].name : "";

  const loggedinUserRole =
    accounts.length > 0 ? accounts[0].idTokenClaims?.roles[0] : "";

  return (
    <div>
      <AppBar
        position="static"
        sx={{
          border: 1,
          backgroundColor: "#EEF5FF",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
            <img src={NiagaraLogo} alt="Your SVG" style={{ width: "4em" }} />
            <Typography
              sx={{
                color: "#006098",
              }}
            >
              |
            </Typography>
            <Typography
              variant="h7"
              noWrap
              component="a"
              sx={{
                fontSize: "1rem",
                fontFamily: "Poppins, sans-serif",
                fontWeight: "bold",
                color: "#006098",
              }}
            >
              Customer Appointments Capture Portal
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <Typography
              sx={{
                color: "#006098",
              }}
            >
              |
            </Typography>
            <PersonIcon sx={{ color: "#006098" }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  marginBottom: 0.5,
                  fontSize: "1rem",
                  fontFamily: "Poppins, sans-serif",
                  color: "#006098",
                  fontWeight: "bold",
                }}
              >
                {loggedinUserName}
              </Typography>
              <Typography
                variant="body3"
                sx={{
                  //marginBottom: 0.5,
                  fontFamily: "Poppins, sans-serif",
                  color: "#006098",
                  fontWeight: "italic",
                }}
              >
                {loggedinUserRole}
              </Typography>
            </Box>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
}
export default Header;
