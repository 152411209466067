import React, { useState } from "react";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import OutlinedInput from "@mui/material/OutlinedInput";
import ClearIcon from "@mui/icons-material/Clear";
import {
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  FormControl,
} from "@mui/material";

import Box from "@mui/material/Box";
import { useFunctionContext } from "../FunctionContext";
import axios from "axios";

function Search() {
  const [searchValue, setSearchValue] = useState("");
  const [criteria, setCriteria] = useState("None");
  const [searching, setSearching] = useState(false);
  const [errorCriteria, setErrorCriteria] = useState(false);
  const [errorInput, setErrorInput] = useState(false);

  const { triggerFunction } = useFunctionContext();

  const criteriaMap = {
    "Appointment Status": "AppointmentStatus",
    "File Name": "FileName",
    "Delivery Number": "OrderReleaseId",
  };

  const baseUrlKey = "BASE_API_ENDPOINT";
  const fetchURLKey = "URL_FETCH_APPOINTMENTS";
  const apiKey = "API_KEY";

  const clearAll = () => {
    triggerFunction([]);
    setCriteria("None");
    setSearchValue("");
    setErrorCriteria(false);
    setErrorInput(false);
  };

  const fetchAppointments = () => {
    setSearching(true);

    var jsonRequest = {};
    if (criteria && criteria !== "None" && searchValue) {
      jsonRequest[criteriaMap[criteria]] = searchValue;
      callSearchApi(jsonRequest);
    } else if (criteria === "None") {
      setErrorCriteria(true);
      setSearching(false);
    } else if (!searchValue) {
      setErrorInput(true);
      setSearching(false);
    }
  };

  const callSearchApi = (jsonRequest) => {
    const url =
      process.env.REACT_APP_BASE_API_ENDPOINT +
      process.env.REACT_APP_URL_FETCH_APPOINTMENTS;

    const requestHeaders = {
      "x-api-key": process.env.REACT_APP_API_KEY,
      "content-type": "application/json",
    };
    triggerFunction([]);
    axios
      .post(url, JSON.stringify(jsonRequest), { headers: requestHeaders })
      .then((response) => {
        triggerFunction(response.data.message);
        setSearching(false);
      })
      .catch((error) => {
        console.error("There was an error while fetching appointments!", error);
        setSearching(false);
      });
  };

  const handleSearchCriteriaChange = (event) => {
    setCriteria(event.target.value);
    if (event.target.value === "None") {
      setErrorCriteria(true);
    } else {
      setErrorCriteria(false);
    }
  };

  const handleSearchCriteriaValue = (event) => {
    setSearchValue(event.target.value);
  };

  return (
    <div align="center" style={{ float: "left", width: "75%" }}>
      <Box
        component="section"
        sx={{
          border: "1px solid gray",
          //margin: 1,
          padding: 1,
          fontWeight: "bold",
          borderRadius: 1,
          background: "#EEF5FF",
          color: "#006098",
        }}
      >
        <legend>Search Customer Appointments</legend>

        <FormControl>
          <InputLabel>Search Criteria</InputLabel>
          <Select
            renderValue={(selected) => (selected ? selected : "None")}
            onChange={handleSearchCriteriaChange}
            value={criteria}
            id="idCriteria"
            sx={{
              fontFamily: "Poppins, sans-serif",

              width: "250px",
              height: "48px",
              borderRadius: "4px",
              margin: 2,
              padding: 2,
              fontWeight: "bold",
              background: "white",
            }}
            error={errorCriteria}
          >
            <MenuItem value="None">None</MenuItem>
            <MenuItem value="File Name">File Name</MenuItem>
            <MenuItem value="Delivery Number">Delivery Number</MenuItem>
            <MenuItem value="Appointment Status">Appointment Status</MenuItem>
          </Select>
          {/* <FormHelperText>{errorCriteria ? "Invalid" : ""}</FormHelperText> */}
        </FormControl>

        <FormControl>
          <InputLabel>Search Value</InputLabel>
          <OutlinedInput
            placeholder={"Search by Status / Delivery Number / File Name"}
            type="text"
            value={searchValue}
            onChange={handleSearchCriteriaValue}
            error={errorInput}
            sx={{
              fontFamily: "Poppins, sans-serif",
              background: "white",
              width: "528px",
              height: "48px",
              borderRadius: "4px",
              margin: 2,
              padding: 2,
            }}
          />
        </FormControl>

        <Button
          variant="contained"
          size="small"
          onClick={fetchAppointments}
          startIcon={<SearchIcon></SearchIcon>}
          sx={{ margin: 2, padding: 2, height: "65px" }}
        >
          <div>
            {searching ? (
              <CircularProgress sx={{ color: "white", size: "0.5rem" }} />
            ) : (
              "Search"
            )}
          </div>
        </Button>

        <Button
          variant="outlined"
          size="small"
          startIcon={<ClearIcon></ClearIcon>}
          onClick={clearAll}
          sx={{ margin: 2, padding: 2, height: "65px" }}
        >
          <div>Clear</div>
        </Button>
      </Box>
    </div>
  );
}

export default Search;
