import * as React from "react";

import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";

import axios from "axios";
import { useFunctionContext } from "../FunctionContext";

const XLSX = require("xlsx");

function Upload() {
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [uploading, setUploading] = React.useState(false);
  const [messageOpen, setMessageOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");

  const { triggerFunction } = useFunctionContext();

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleMessageClose = () => {
    setSuccessMessage("");

    setMessageOpen(false);
  };

  async function handleFileChange(event) {
    console.log(event.target.files[0]);

    setSelectedFile(event.target.files[0]);
    setUploading(true);
    await uploadAppointments(event.target.files[0]);
  }

  async function uploadAppointments(file) {
    const excelData = await file.arrayBuffer();
    const wb = XLSX.read(excelData);

    const sheetList = wb.SheetNames;

    if (sheetList.length > 0) {
      for (var j = 0; j < sheetList.length; j++) {
        const data = XLSX.utils.sheet_to_json(wb.Sheets[sheetList[j]], {
          raw: false,
          dateNF: 'yyyy"-"mm"-"dd" "hh":"MM":"ss',
        });

        var finalArray = [];
        for (var i = 0; i < data.length; i++) {
          const row = data[i];

          finalArray.push(createNewRow(file, row));
        }

        callUploadApi(finalArray);
      }
    } else {
      console.log("No content in the sheet to upload");
    }
  }

  function createNewRow(file, row) {
    var newRow = {};
    newRow.OrderReleaseId = row["Order Release ID"];
    newRow.EarlyDeliveryDate = row["Early Delivery Date"];
    newRow.LateDeliveryDate = row["Late Delivery Date"];
    newRow.DeliveryAppointment = row["Delivery Appointment"];
    newRow.EarlyPickupDate = row["Early Pickup Date"];
    newRow.LatePickupDate = row["Late Pickup Date"];
    newRow.PickupAppointment = row["Pickup Appointment"];
    newRow.RefValue = row["Ref VALUE"];
    newRow.CPUContact = row["CPU CONTACT"];
    newRow.PendingReasonCode = row["Pending Reason Code"];
    newRow.Preload = row["Preload"];
    newRow.RateOfferingId = row["Rate Offering ID"];
    newRow.ShipWithGroup = row["Ship With Group"];
    newRow.DropOffRoutingSeq = row["Dropoff Routing Seq"];
    newRow.SpotImage = row["Spot Image"];
    newRow.SpotReasonCode = row["Spot reason code"];
    newRow.ScheduledImage = row["Scheduled Image"];
    newRow.Timezone = row["Time Zone"];
    newRow.AppointmentStatus = row["Customer Portal Appointment Status"];
    newRow.FileName = file.name;
    newRow.CreatedBy = "web";

    return newRow;
  }

  function callUploadApi(jsonRequest) {
    const url =
      process.env.REACT_APP_BASE_API_ENDPOINT +
      process.env.REACT_APP_URL_UPLOAD_APPOINTMENTS;

    const requestHeaders = {
      "x-api-key": process.env.REACT_APP_API_KEY,
      "content-type": "application/json",
    };

    axios
      .post(url, JSON.stringify(jsonRequest), { headers: requestHeaders })
      .then((response) => {
        console.log(response.data.message);
        triggerFunction(mergeSuccessAndFailedAppointments(response));

        const successCount =
          "Customer Appointment(s) Upload => Success Counts:" +
          response.data.successful.length +
          "::Failed Counts:" +
          response.data.failed.length;

        setSuccessMessage(successCount);
        setUploading(false);
        setMessageOpen(true);
      })
      .catch((error) => {
        console.error("There was an error while uploading file!", error);
        setUploading(false);
        setSuccessMessage("There was an error while uploading file!");
        setMessageOpen(true);
      });
  }

  function mergeSuccessAndFailedAppointments(response) {
    const success = response.data.successful;
    const failures = response.data.failed;

    var mergedAppointments = [];

    for (let arrVal of success) {
      mergedAppointments.push(arrVal);
    }

    for (let arrVal of failures) {
      mergedAppointments.push(arrVal);
    }

    return mergedAppointments;
  }

  return (
    <div align="center" style={{ float: "left", width: "25%" }}>
      <Box
        component="section"
        sx={{
          p: 1,
          border: "1px solid grey",
          //margin: 1,
          //padding: 1,
          fontWeight: "bold",
          borderRadius: 1,
          background: "#EEF5FF",
          color: "#006098",
        }}
      >
        <legend align="center">Select a file to upload</legend>
        <Button
          component="label"
          size="small"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
          sx={{
            fontFamily: "Poppins, sans-serif",
            height: "65px",
            borderRadius: "4px",
            margin: 2,
            padding: 2,
          }}
        >
          <div>
            {uploading ? (
              <CircularProgress sx={{ color: "white" }} />
            ) : (
              "Upload File"
            )}
          </div>
          <VisuallyHiddenInput
            type="file"
            onChange={handleFileChange}
            multiple
          />
        </Button>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={messageOpen}
          onClose={handleMessageClose}
          key={"top" + "center"}
          autoHideDuration={5000}
        >
          <Alert
            severity="info"
            variant="filled"
            sx={{ width: "100%" }}
            message={successMessage}
          >
            {successMessage}
          </Alert>
        </Snackbar>

        <Button
          variant="outlined"
          size="small"
          startIcon={<CloudDownloadIcon fontSize="inherit" />}
          href="API Scheduling Mapping Template.xlsx"
          download={true}
          sx={{
            fontFamily: "Poppins, sans-serif",
            height: "65px",
            borderRadius: "4px",
            margin: 2,
            padding: 2,
          }}
        >
          Download <br></br>Template
        </Button>
      </Box>
    </div>
  );
}

export default Upload;
