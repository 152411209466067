// FunctionContext.js
import React, { createContext, useContext, useState } from "react";

// Create the context
const FunctionContext = createContext();

// Custom hook to use the context
export const useFunctionContext = () => {
  return useContext(FunctionContext);
};

// Provider component
const FunctionProvider = ({ children }) => {
  const [triggerFunction, setTriggerFunction] = useState(null);

  return (
    <FunctionContext.Provider value={{ triggerFunction, setTriggerFunction }}>
      {children}
    </FunctionContext.Provider>
  );
};

export default FunctionProvider;
