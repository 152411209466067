import { PublicClientApplication } from "@azure/msal-browser";
import { LogLevel } from "@azure/msal-browser";
import AppSettings from "./AuthSettings";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0;

const msalConfig = {
  auth: {
    clientId: AppSettings.AD_CLIENT_ID,
    authority: AppSettings.adTenantUrl(),
    redirectUri: AppSettings.AD_REDIRECT_URI,
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

const loginRequest = {
  scopes: ["User.Read"],
};
const msConfig = new PublicClientApplication(msalConfig);

const userInfo = { userIsAuthenticated: true };

export { msConfig, loginRequest, userInfo };
