import { EventType, InteractionType } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  useMsal,
  useMsalAuthentication,
} from "@azure/msal-react";
import { loginRequest } from "./AuthService";

function AuthAD(props) {
  const { instance } = useMsal();
  useMsalAuthentication(InteractionType.Redirect, loginRequest);

  instance.addEventCallback((message) => {
    if (message.eventType === EventType.LOGIN_SUCCESS) {
      console.log("Login success is coming");
      const { payload } = message;
    }
    // Handled login error for BrowserAuthError Status Code
    if (message.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
      instance.loginRedirect(loginRequest);
    }
  });

  return <AuthenticatedTemplate>{props.children}</AuthenticatedTemplate>;
}

export default AuthAD;
